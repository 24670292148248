<template>
  <b-card-code no-body title="Admin List">
      <div class="dt_adv_search ml-1 mr-1">
        <div class="row">

          <div class="col-md-12">
              <b-modal no-close-on-backdrop id="form-Admin-modal"  size="lg" :title="editId != null ? 'Edit Admin' : 'Add Admin'">
                <div class="form">

                  <h5>Account Information</h5>

                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="">Name:</label>
                        <input type="text" class="form-control" v-model="formPayload.name" placeholder="Admin name...">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="">Email:</label>
                        <input type="email" class="form-control" v-model="formPayload.email" placeholder="Admin email...">
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="">Password:</label>
                    <input type="password" class="form-control" v-model="formPayload.password" placeholder="Admin password...">
                  </div>

                  <div class="form-group">
                    <label for="">Password Confirmation:</label>
                    <input type="password" class="form-control" v-model="formPayload.password_confirmation" placeholder="Admin password...">
                  </div>

                  <!-- Roles -->
                  <br>
                  <h5>Roles</h5>
        
                  <div class="row">

                    <div class="col-3" v-for="role in roles" :key="role.id">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input" type="checkbox" v-model="formPayload.roles" :value="role.id" :id="'role_checkbox_' + role.id">
                          <label class="custom-control-label text-capitalize" :for="'role_checkbox_' + role.id">
                            {{ role.name }}
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                <template #modal-footer="{}">
                  <b-button v-if="editId == null" variant="success" @click="createItem()">
                    Save Admin
                  </b-button>
                  <b-button v-else variant="success" @click="updateItem()">
                    Save Admin
                  </b-button>
                </template>
              </b-modal>
          </div>

            <div class="col-12">
                <div class="form-row">
                    <div class="col-lg-4">
                      <b-button @click="cleanUpForm()" v-if="checkPermission('create admin')" v-b-modal.form-Admin-modal class="btn btn-info btn-sm mb-1" data-toggle="tooltip" data-placement="top" title="Add Admin">
                        <feather-icon
                            icon="PlusCircleIcon"
                        />
                        Add Admin
                      </b-button>
                    </div>
                    <div class="col-lg-4">
                        
                    </div>
                    <div class="col-lg-4">
                        <input type="text" v-model="filter.keyword" placeholder="Search by name or email" class="form-control form-control-sm float-right" style="width: 200px;">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>#</th>
            <th>Admin</th>
            <th>Role</th>
            <th>Registration Time</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="5" class="text-center">
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr v-else v-for="item in result.data" v-bind:key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}<br><small>{{ item.email }}</small></td>
            <td>
                <span v-for="role in item.roles" :key="role" class="badge text-capitalize badge-light-success badge-pill">{{ role }}</span>
            </td>
            <td>{{ item.register_at == null ? '-' : item.register_at }}</td>
            <td>
              <b-dropdown
                    variant="info"
                    size="sm"
                >
                    <template #button-content>
                      Action
                    </template>
                    <b-dropdown-item v-if="checkPermission('update admin')" @click="editItem(item)">
                      <feather-icon
                          icon="EditIcon"
                          class="mr-75"
                      />
                      Edit Admin
                    </b-dropdown-item>
                    <b-dropdown-item v-if="checkPermission('delete admin') && !item.roles.includes('super admin')" @click="deleteItem(item.id)">
                      <feather-icon
                          icon="TrashIcon"
                          class="mr-75"
                      />
                      Delete Admin
                    </b-dropdown-item>
                </b-dropdown>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td colspan="5" class="text-center">Data is empty.</td>
          </tr>
        </tbody>
      </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue';
import VuejsDialog from 'vuejs-dialog';
Vue.use(VuejsDialog);
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

export default {
  title () {
    return `Admin List`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BSpinner
  },
  watch: {
    filter: {
      handler: _.debounce(function() {    
        this.getData();                                                                                                                                                                        
      }, 300),
      deep: true
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        keyword: '',
      },
      isLoading: false,
      formPayload: Object,
      editId: null,
      roles: [],
    }
  },
  created() {
    this.getData();
    this.loadFormData();

    if (!checkPermission('show admins')) {
      window.location.href = window.location.origin + "/error-403";
    }
  },
  methods: {
    cleanUpForm() {
      this.editId = null
      this.formPayload = {
        name: '',
        email: '',
        // epact_vendor_id: '',
        password: '',
        password_confirmation: '',
        // phone: '',
        // address: '',
        // photo: '',
        // country: '',
        // province: '',
        // city: '',
        // pic_name: '',
        // pic_position: '',
        is_active: true,
        roles: [],
        // grant_restricted_commodity: [],
      };

      // Prepare restricted commodity
      // this.addGrantedCommodity()
    },
    loadFormData() {
      // Load Roles
      this.$http.get('/admin/acl/roles', { useCache: true })
      .then(response => {
        this.roles = response.data.data.data
      })
    },
    createItem() {
      var form = this.preparePayload();

      if (this.formPayload.roles.length > 0) {
        this.$http.post('/admin/users', form, {
          headers: { 'content-type': 'multipart/form-data' }
        })
        .then(response => {

          this.$bvModal.hide('form-Admin-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Admin successfully created')
          this.cleanUpForm();

        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)  
          }
        })
      } else {
        alert('Select role!');
      }
    },
    updateItem() {
      var form = this.preparePayload();
      form.append('_method', 'PATCH');

      if (this.formPayload.roles.length > 0) {
        this.$http.post('/admin/users/' + this.editId, form, {
          headers: { 'content-type': 'multipart/form-data' }
        })
        .then(response => {

          this.$bvModal.hide('form-Admin-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Admin successfully updated!')
          this.cleanUpForm();

        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)          
          }
        })
      } else {
        alert('Select role!');
      }
    },
    preparePayload() {
      this.formPayload.is_active = 1;
      
      var form = new FormData();
      for (var key in this.formPayload) {
          if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
            form.append(key, this.formPayload[key]);
          }
      }

      // Prepare role payload
      this.formPayload.roles.forEach(function(item){
        form.append('roles[]', item);
      });

      return form;
    },
    editItem(item) {
      this.editId = item.id

      // Getting roles id
      var roleId = []
      var context = this
      item.roles.forEach(function(roleName){
        context.roles.forEach(function(role){
          if (roleName == role.name) {
            roleId.push(role.id);
          }
        })
      })

      this.formPayload = {
        name: item.name,
        email: item.email,
        password: item.password,
        password_confirmation: item.password_confirmation,
        roles: roleId,   
      }

      this.$bvModal.show('form-Admin-modal')
    },
    getData(page = 1) {

      this.isLoading = true;
      this.currentPage = page;
      var queryParams = this.filter
      queryParams.page = page
      queryParams.user_type = 'admin'

      this.$http.get('/admin/users', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
        this.isLoading = false;
      })

    },
    deleteItem(id) {

      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this user?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.$http.delete('admin/users/' + id)
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'User successfully deleted',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)   
            }
          })

        }
      })

    },
  }
}
</script>